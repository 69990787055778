<template>
  <div
    id="signup"
    style="overflow: hidden; width: 100%; height: 100vh"
    class="d-flex align-center"
    :class="$vuetify.breakpoint.mobile ? 'px-10' : 'px-15'"
  >
    <v-form
      v-if="showform"
      class="flex-grow-1"
      v-model="formvalid"
      :disabled="processing"
    >
      <div class="d-flex justify-center flex-column mb-5">
        <div
          class="primary--text mb-3"
          :style="
            $vuetify.breakpoint.mobile ? 'font-size: 7vw' : 'font-size: 2vw'
          "
          v-html="$t('AUTH.FORGOT.header')"
        ></div>
      </div>
      <template v-if="step === 1">
        <v-text-field
          hide-details="auto"
          background-color="rgba(165, 203, 239, 0.42)"
          filled
          ref="user_email"
          class="mt-3"
          rounded
          color="primary"
          :rules="emailRules"
          required
          dense
          single-line
          v-model.trim="form.email"
          :label="$t('AUTH.LOGIN.EMAIL')"
          value=""
        >
        </v-text-field>

        <v-snackbar v-model="snackbar" multi-line timeout="5000" color="error">
          {{ error }}

          <template v-slot:action="{ attrs }">
            <v-btn color="white" icon v-bind="attrs" @click="snackbar = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <div class="mt-5">
          <v-btn
            @click="checkEmail"
            :disabled="!formvalid"
            :loading="processing"
            rounded
            elevation="0"
            block
            color="green darken-3"
            class="white--text"
            >{{ $t("AUTH.FORGOT.verifyemail") }}</v-btn
          >
        </div>
      </template>
      <template v-else-if="step === 2">
        <div class="d-flex justify-center align-center flex-column">
          <span style="font-size: 1rem">{{
            $t("AUTH.REG_STEPS.varifyEmail.label")
          }}</span>
          <v-row :style="$vuetify.breakpoint.mobile ? '' : 'max-width: 80%'">
            <v-col class="mx-2">
              <v-text-field
                ref="otp1"
                v-model="otp.s1.input"
                solo
                flat
                hide-details="auto"
                background-color="rgba(165, 203, 239, 0.42)"
                color="primary"
                height="55px"
                autofocus
                @input="stepinput(1)"
                @keyup.backspace.exact="inputback(1)"
                @keydown.backspace.exact.prevent
                v-mask="'#'"
                class="centered-input"
                @paste="onPaste"
                :type="$vuetify.breakpoint.mobile ? 'number' : 'text'"
              />
            </v-col>
            <v-col>
              <v-text-field
                ref="otp2"
                v-model="otp.s2.input"
                hide-details="auto"
                solo
                flat
                background-color="rgba(165, 203, 239, 0.42)"
                color="primary"
                height="55px"
                v-mask="'#'"
                class="centered-input"
                @input="stepinput(2)"
                @keyup.backspace.exact="inputback(2)"
                @keydown.backspace.exact.prevent
                :type="$vuetify.breakpoint.mobile ? 'number' : 'text'"
              />
            </v-col>
            <v-col>
              <v-text-field
                ref="otp3"
                v-model="otp.s3.input"
                hide-details="auto"
                solo
                flat
                background-color="rgba(165, 203, 239, 0.42)"
                color="primary"
                height="55px"
                class="centered-input"
                v-mask="'#'"
                @input="stepinput(3)"
                @keyup.backspace.exact="inputback(3)"
                @keydown.backspace.exact.prevent
                :type="$vuetify.breakpoint.mobile ? 'number' : 'text'"
              />
            </v-col>
            <v-col>
              <v-text-field
                ref="otp4"
                v-model="otp.s4.input"
                hide-details="auto"
                solo
                flat
                background-color="rgba(165, 203, 239, 0.42)"
                color="primary"
                height="55px"
                class="centered-input"
                v-mask="'#'"
                @input="stepinput(4)"
                @keyup.backspace.exact="inputback(4)"
                @keydown.backspace.exact.prevent
                @keydown.enter.exact.prevent
                @keyup.enter.exact="checkcode()"
                :type="$vuetify.breakpoint.mobile ? 'number' : 'text'"
              />
            </v-col>
          </v-row>
        </div>
        <v-alert dense dismissible type="error" v-model="otperror"
          ><span style="font-size:0.9rem">{{
            $t("AUTH.FORGOT.otperror")
          }}</span></v-alert
        >
        <v-row
          class="my-0 py-0"
          :class="$vuetify.breakpoint.mobile ? 'flex-row-reverse' : ''"
        >
          <v-col
            v-if="!$vuetify.breakpoint.mobile"
            cols="12"
            lg="6"
            md="6"
            class="my-0 py-0"
          >
            <v-btn
              @click="checkEmail"
              rounded
              outlined
              block
              elevation="0"
              color="primary"
              >{{ $t("AUTH.FORGOT.resend") }}</v-btn
            >
          </v-col>
          <v-col class="my-0 py-0">
            <v-btn
              @click="checkOTP"
              :disabled="!formvalid || verifyingotp"
              :loading="processing || verifyingotp"
              rounded
              elevation="0"
              block
              color="green darken-3"
              class="white--text"
              >{{ $t("AUTH.FORGOT.verifyotp") }}</v-btn
            >
          </v-col>
          <v-col
            v-if="$vuetify.breakpoint.mobile"
            cols="12"
            lg="6"
            md="6"
            class="my-0 py-0 mt-3"
          >
            <v-btn
              @click="checkEmail"
              rounded
              outlined
              block
              elevation="0"
              color="primary"
              >{{ $t("AUTH.FORGOT.resend") }}</v-btn
            >
          </v-col>
        </v-row>
      </template>

      <template v-else-if="step === 3">
        <v-text-field
          hide-details="auto"
          background-color="rgba(165, 203, 239, 0.42)"
          class="mt-3"
          type="password"
          :rules="[v => !!v || $t('AUTH.LOGIN.PASSVALIDATION')]"
          required
          filled
          rounded
          dense
          single-line
          color="primary"
          v-model="password"
          :label="$t('AUTH.LOGIN.PASSWORD')"
          value=""
        />

        <div class="mt-5">
          <v-btn
            @click="resetPass"
            :disabled="!formvalid || isresetting"
            :loading="processing || isresetting"
            rounded
            elevation="0"
            block
            color="green darken-3"
            class="white--text"
            >{{ $t("AUTH.FORGOT.reset") }}</v-btn
          >
        </div>
      </template>

      <template v-if="step !== 3">
        <v-divider />
        <v-row class="my-0 py-0">
          <v-col class="my-0 py-0">
            <router-link :to="{ name: 'login' }">
              <v-btn rounded block elevation="0" color="primary">{{
                $t("AUTH.LOGIN.BUTTON")
              }}</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
    </v-form>
    <div
      style="
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      "
      v-else
    >
      <lottie-animation
        style="height: 100px"
        :animationData="require('@/assets/animation/confetti.json')"
        :loop="true"
      />
      <div class="text-muted text-center">
        {{ $t("AUTH.FORGOT.SUCCESS") }}
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  name: "register",

  components: { LottieAnimation },
  data() {
    return {
      languageFlag: "",
      opendocreg: false,
      showformonly: false,
      initialheight: 0,
      snackbar: false,
      // Remove this dummy login info
      formvalid: false,
      emailRules: [
        v => !!v || this.$t("AUTH.LOGIN.EMAILVALIDATION"),
        v => /.+@.+/.test(v) || this.$t("AUTH.LOGIN.EMAILVALIDATION")
      ],

      submitted: false,
      processing: false,
      form: {
        email: ""
      },
      error: "",
      checkingemail: false,
      password: "",
      verifyingotp: false,
      isresetting: false,
      otperror: false,
      showform: true,
      //varification steps
      step: 1,
      // otp
      otp: {
        s1: {
          focus: true,
          input: ""
        },
        s2: {
          focus: false,
          input: ""
        },
        s3: {
          focus: false,
          input: ""
        },
        s4: {
          focus: false,
          input: ""
        }
      }
    };
  },
  mounted() {},
  methods: {
    resetForm() {
      this.form = {
        email: ""
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    async checkEmail() {
      this.$user.forgotPasswordSendOTP({
        email: this.form.email,
        device: this.$util.getDeviceInfo(),
        ip: JSON.parse(localStorage.geoData).ip
      });
      this.step = 2;
    },
    async checkOTP() {
      this.verifyingotp = true;
      this.$user
        .verifyOTP({
          email: this.form.email,
          otp:
            this.otp.s1.input +
            "" +
            this.otp.s2.input +
            "" +
            this.otp.s3.input +
            "" +
            this.otp.s4.input
        })
        .then(res => {
          // console.log("response from verify otp", res);
          if (res.data.data.includes("success")) {
            this.step = 3;
            this.verifyingotp = false;
          } else {
            this.verifyingotp = false;
            this.otperror = true;
          }
        });
    },
    async resetPass() {
      this.isresetting = true;
      this.$user
        .resetpass({
          email: this.form.email,
          otp:
            this.otp.s1.input +
            "" +
            this.otp.s2.input +
            "" +
            this.otp.s3.input +
            "" +
            this.otp.s4.input,
          password: this.password
        })
        .then(res => {
          this.isresetting = false;
          // console.log("response",res)
          if (res.data.data.includes("done")) {
            this.showform = false;
            setTimeout(() => {
              this.$router.push({ name: "login" });
            }, 2000);
          }
        });
    },

    stepinput(s) {
      switch (s) {
        case 1: {
          this.$refs.otp2.focus();
          break;
        }
        case 2: {
          this.$refs.otp3.focus();
          break;
        }
        case 3: {
          this.$refs.otp4.focus();
          break;
        }
        case 4: {
          break;
        }
      }
    },
    inputback(s) {
      switch (s) {
        case 1: {
          this.otp.s1.input = "";
          break;
        }
        case 2: {
          this.otp.s2.input = "";
          this.$refs.otp1.focus();
          break;
        }
        case 3: {
          this.otp.s3.input = "";
          this.$refs.otp2.focus();
          break;
        }
        case 4: {
          this.otp.s4.input = "";
          this.$refs.otp3.focus();
          break;
        }
      }
    },
    onPaste(e) {
      var value = e.clipboardData.getData("text/plain");
      for (var i = 0; i < value.length && i < 4; i++) {
        this.otp["s" + (i + 1)].input = value[i];
        //console.log(this.otp['s'+(i+1)].input)
      }
    }
  },
  watch: {},
  computed: {}
};
</script>
<style>
.v-application ul {
  padding-left: 0px !important;
}
.theme--light.v-text-field--solo-inverted > .v-input__control > .v-input__slot {
  padding: 15px 18px !important;
  background-color: #f3f6f9 !important;
  color: rgb(63, 66, 84) !important;
}

.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot {
  background-color: rgb(232, 240, 254) !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot {
  background-color: transparent !important;
}
.theme--light.v-text-field--solo-inverted.v-input--is-focused
  > .v-input__control
  > .v-input__slot
  input,
label {
  color: rgb(63, 66, 84) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f3f6f9 inset;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(232, 240, 254) inset;
}

.animate_ar_mobile {
  margin-right: auto;
  margin-left: auto;
  z-index: 0;
  clip-path: circle(45%);
  background-color: #f8d000;
}

.animate_en_mobile {
  margin-right: auto;
  margin-left: auto;
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
  z-index: 0;
}

.centered-input input {
  text-align: center;
}
</style>
